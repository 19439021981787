// import Dashboard from "views/Dashboard/Dashboard";
// import UserProfile from "views/UserProfile/UserProfile";
// import TableList from "views/TableList/TableList";
// import Typography from "views/Typography/Typography";
// import Icons from "views/Icons/Icons";
// import Maps from "views/Maps/Maps";
// import Notifications from "views/Notifications/Notifications";
// import Upgrade from "views/Upgrade/Upgrade";

const adminRoutes = [

  { path: '/clients', name: 'Klijenti', icon: 'pe-7s-users' },
  { path: '/EInvoices', name: 'E fakture', icon: 'pe-7s-file' },
  { path: '/deletedClients', name: 'Izbrisani klijenti', icon: 'pe-7s-junk' },
  { path: '/BlackList', name: 'Lista blokiranih uredajaj', icon: 'pe-7s-attention' },
  { path: '/profiles', name: 'Admin/Korisnici', icon: 'pe-7s-id' },
  { path: '/licence', name: 'Registruj licencu', icon: 'pe-7s-ticket' },
  { path: '/PersonalLogs', name: 'Logovi', icon: 'pe-7s-notebook' },
  //{ path: '/warning', name: 'Za iskljucenje', icon: 'pe-7s-stopwatch' },
  { path: '/invoice', name: 'Registruj e-fakture licencu', icon: 'pe-7s-ticket' },
  { path: '/statistics', name: 'Statistika', icon: 'pe-7s-graph3' },
  { path: '/edit-profile', name: 'Profil', icon: 'pe-7s-user' },
  { path: '/DistCheckTable', name: 'Distributeri', icon: 'pe-7s-display1' },
  { path: '/register', name: 'Register', icon: 'pe-7s-add-user' },
  { path: '/galebstart', name: 'Start', icon: 'pe-7s-user' },
  { path: '/alati', name: 'Alati', icon: 'pe-7s-tools' },
  { path: '/galebshop', name: 'Admin Eshop', icon: 'pe-7s-cart' },
  { path: '/NewLicenceRegistration', name: 'Galeb LPFR/POS', icon: 'pe-7s-add-user'},
  { redirect: true, path: '/confirmation', to: '/confirmation' },
  { redirect: true, path: '/iconfirmation', to: '/iconfirmation' },
  { redirect: true, path: '/', to: '/login', name: 'Login' },

];

export default adminRoutes;
