import React, { Component } from 'react';
import {
  Route,
  Switch,
  Redirect
} from 'react-router-dom';
import { connect } from 'react-redux';
import NotificationSystem from 'react-notification-system';
import store from '../../store';
import jwt_decode from 'jwt-decode';
import setAuthToken from '../../utils/setAuthToken';
import { setCurrentUser, logoutUser } from '../../actions/authActions';
import { clearCurrentProfile } from '../../actions/profileAction';
import PrivateRoute from '../../components/common/PrivateRoute';
import Client from '../../components/client/Client';
import ClientTable from '../../components/clients/ClientTable.js';
import AddClient from '../../components/add-elements/AddClient';
import AddClientDist from '../../components/add-elements/AddClientDist';
import RemoveClientDist from '../../components/add-elements/RemoveClientDist';
import RemoveClient from '../../components/add-elements/RemoveClient';
import Login from '../../components/auth/Login';
import Register from '../../components/auth/Register';
import EditProfile from '../../components/edit-profile/EditProfile';
import Profiles from '../../components/profiles/Profiles';
import ProfileEdit from '../../components/profiles/ProfileEdit';
import Licence from '../../components/add-elements/AddLicence';
import Confirmation from '../../components/add-elements/Clients';
import InvoicesConfrimation from '../../components/add-elements/Invoice'
import Invoice from '../../components/add-elements/AddInvoice';
import InvoiceDist from '../../components/add-elements/AddInvoiceDist';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import Sidebar from '../../components/Sidebar/Sidebar';
//import Snowfall from 'react-snowfall';
import ClientCallHistory from '../../components/clients/ClientCallHistory';

import { style } from '../../variables/Variables.jsx';

import RegOnlyRoutes from '../../routes/regOnlyRoutes';
import distRoutes from '../../routes/dist.jsx';
import komercRoutes from '../../routes/komer.jsx';
import adminRoutes from '../../routes/admin.jsx';
import pmRoutes from '../../routes/pm.jsx';
import ClientInfo from '../../components/clients/ClientInfo';
import HistoryTable from '../../components/clients/HistoryTable';
import LoginYettel from '../../components/auth/LoginYettel';
import LoginTelekom from '../../components/auth/LoginTelekom';
import ChangeInvoiceDetails from '../../components/add-elements/ChangeInvoiceDetails';
import CreategalebStart from '../../components/clients/CreategalebStart';
import EditGalebStart from '../../components/clients/EditGalebStart';
import GSClientCallHistory from '../../components/clients/GSClientCallHistory';
import tools from '../../components/statistics/tools';
import AdminEShop from '../../components/add-elements/AdminEShop';
import CreateProduct from '../../components/add-elements/CreateProduct';
import AllProducts from '../../components/add-elements/AllProducts';
import UpdateProduct from '../../components/add-elements/UpdateProduct';
import Orders from '../../components/add-elements/Orders';
import eshopRoutes from '../../routes/eshop';
import CardOrders from '../../components/add-elements/CardOrders';
import CashOrders from '../../components/add-elements/CashOrders';
import IPSOrders from '../../components/add-elements/IPSOrders';
import PredracunOrders from '../../components/add-elements/PredracunOrders';
import ClientTableDist from '../../components/clients/ClientTableDist.js';
import ClientsEInvoiceTable from '../../components/clients/ClientsEInvoiceTable.js';
import BlackListTable from '../../components/blacklist/BlackListTable.js';
import BlackListDistTable from '../../components/blacklist/BlackListDistTable.js';
import TableWarning from '../../components/clients/TableWarning.js';
import Statistics from '../../components/statistics/Statistics.js';
import DeletedClientsTable from '../../components/clients/DeletedClientsTable.js';
import DeletedClientsDistTable from '../../components/clients/DeletedClientsDistTable.js';
import ClientsEInvoiceTableDist from '../../components/clients/ClientsEInvoiceTableDist.js';
import ClientTablePM from '../../components/clients/ClientTablePM.js';
import PersonalLogsPage from '../../components/clients/PersonalLogsPage.js';
import ClientsDistDuty from '../../components/clients/ClientsDistDuty.js';
import komercDistRoutes from '../../routes/KomerDist.jsx';
import AllOrdersVendor from '../../components/add-elements/AllOrdersVendor.js';
import NewLicenceRegistration from '../../components/add-elements/NewLicenceRegistration.js';

//Check for token
if (localStorage.jwtToken) {
  // Set auth token header auth
  setAuthToken(localStorage.jwtToken);
  // Decode token and get user info and exp
  const decoded = jwt_decode(localStorage.jwtToken);
  // Set user and isAuthenticated
  store.dispatch(setCurrentUser(decoded));

  // Check for expired token
  const currentTime = Date.now() / 1000;
  if (decoded.exp < currentTime) {
    // Logout user
    store.dispatch(logoutUser());
    // Clear current Profile
    store.dispatch(clearCurrentProfile());
    // Redirect to login
    window.location.href = '/login';
  }
}

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.componentDidMount = this.componentDidMount.bind(this);
    this.handleNotificationClick = this.handleNotificationClick.bind(this);
    this.state = {
      _notificationSystem: null
    };
  }
  handleNotificationClick(position) {
    var color = Math.floor(Math.random() * 4 + 1);
    var level;
    switch (color) {
      case 1:
        level = 'success';
        break;
      case 2:
        level = 'warning';
        break;
      case 3:
        level = 'error';
        break;
      case 4:
        level = 'info';
        break;
      default:
        break;
    }
    this.state._notificationSystem.addNotification({
      title: <span data-notify="icon" className="pe-7s-gift" />,
      message: (
        <div>
          Welcome to <b>Light Bootstrap Dashboard</b> - a beautiful freebie for
          every web developer.
        </div>
      ),
      level: level,
      position: position,
      autoDismiss: 15
    });
  }
  componentDidMount() {
    this.setState({ _notificationSystem: this.refs.notificationSystem });
    var _notificationSystem = this.refs.notificationSystem;
    var color = Math.floor(Math.random() * 4 + 1);
    var level;
    switch (color) {
      case 1:
        level = 'success';
        break;
      case 2:
        level = 'warning';
        break;
      case 3:
        level = 'error';
        break;
      case 4:
        level = 'info';
        break;
      default:
        break;
    }
  }
  componentDidUpdate(e) {
    if (
      window.innerWidth < 993 &&
      e.history.location.pathname !== e.location.pathname &&
      document.documentElement.className.indexOf('nav-open') !== -1
    ) {
      document.documentElement.classList.toggle('nav-open');
    }
    if (e.history.action === 'PUSH') {
      document.documentElement.scrollTop = 0;
      document.scrollingElement.scrollTop = 0;
      this.refs.mainPanel.scrollTop = 0;
    }
  }
  render() {
    let route;
    if (this.props.auth.isAuthenticated) {
      if (this.props.auth.user.position === 'Admin') {
        route = adminRoutes;
      } else if (this.props.auth.user.position === 'PM') {
        route = pmRoutes;
      } else if (this.props.auth.user.position === 'Dist') {
        route = distRoutes;
      } else if (this.props.auth.user.position === 'Komercijalista') {
        if (this.props.auth.user.distManager == "Yes") {
          route = komercDistRoutes
        } else {
          route = komercRoutes;
        }
      } else if (this.props.auth.user.position === 'RegOnly') {
        route = RegOnlyRoutes;
        console.log('RegOnly')
      } else if (this.props.auth.user.position === 'EShop') {
        route = eshopRoutes;
        console.log('EShop')
      }
      else {
        console.log('Error')
      }
    } else {
      route = [
        { path: '/loginTelekom', to: '/loginTelekom', name: 'LoginTelekom' },
        { path: '/loginYettel', to: '/loginYettel', name: 'LoginYettel' },
        { redirect: true, path: '/', to: '/login', name: 'Login' },
      ]
    }
    return (
      <div className="wrapper">
        {/*<Snowfall />*/}
        <NotificationSystem ref="notificationSystem" style={style} />
        <Sidebar {...this.props} />
        <div id="main-panel" className="main-panel" ref="mainPanel">
          <Header {...this.props} />
          <Route path='/download' />
          <Route exact path="/login" component={Login} />
          <Route exact path="/loginYettel" component={LoginYettel} />
          <Route exact path="/loginTelekom" component={LoginTelekom} />
          <Switch>
            {route.map((prop, key) => {
              if (prop.name === 'Notifications')
                return (
                  <Route
                    path={prop.path}
                    key={key}
                    render={routeProps => (
                      <prop.component
                        {...routeProps}
                        handleClick={this.handleNotificationClick}
                      />
                    )}
                  />
                );
              if (prop.redirect)
                return <Redirect from={prop.path} to={prop.to} key={key} />;
              return (
                <Route path={prop.path} component={prop.component} key={key} />
              );
            })}
          </Switch>
          <Switch>
            <PrivateRoute exact path='/NewLicenceRegistration' component={NewLicenceRegistration} />
          </Switch>
          <Switch>
            <PrivateRoute exact path='/DistCheckTable' component={ClientsDistDuty} />
          </Switch>
          <Switch>
            <PrivateRoute exact path='/PersonalLogs' component={PersonalLogsPage} />
          </Switch>
          <Switch>
            <PrivateRoute exact path='/EInvoices/ChangeInvoiceData/:_id' component={ChangeInvoiceDetails} />
          </Switch>
          <Switch>
            <PrivateRoute exact path='/EInvoicesDist/ChangeInvoiceData/:_id' component={ChangeInvoiceDetails} />
          </Switch>
          <Switch>
            <PrivateRoute exact path='/clients/ClientCallHistory/:_id' component={ClientCallHistory} />
          </Switch>
          <Switch>
            <PrivateRoute exact path='/clientsPM/ClientCallHistory/:_id' component={ClientCallHistory} />
          </Switch>
          <Switch>
            <PrivateRoute exact path='/clientsDist/ClientCallHistory/:_id' component={ClientCallHistory} />
          </Switch>
          <Switch>
            <PrivateRoute exact path='/licenceRegistration' component={Licence} />
          </Switch>
          <Switch>
            <PrivateRoute exact path="/EInvoices" component={ClientsEInvoiceTable} />
          </Switch>
          <Switch>
            <PrivateRoute exact path="/InvoiceRegistration" component={InvoiceDist} />
          </Switch>
          <Switch>
            <PrivateRoute exact path="/BlackList" component={BlackListTable} />
          </Switch>
          <Switch>
            <PrivateRoute exact path="/BlackListDist" component={BlackListDistTable} />
          </Switch>
          <Switch>
            <PrivateRoute exact path="/clientsDist/remove-client/:_id" component={RemoveClientDist} />
          </Switch>
          <Switch>
            <PrivateRoute exact path="/clientsPM/remove-client/:_id" component={RemoveClientDist} />
          </Switch>
          <Switch>
            <PrivateRoute exact path="/clients/remove-client/:_id" component={RemoveClient} />
          </Switch>
          <Switch>
            <PrivateRoute exact path="/clients/add-client/:_id" component={AddClient} />
          </Switch>
          <Switch>
            <PrivateRoute exact path="/clientsDist/add-client/:_id" component={AddClientDist} />
          </Switch>
          <Switch>
            <PrivateRoute exact path="/edit-profile" component={EditProfile} />
          </Switch>
          <Switch>
            <PrivateRoute exact path="/clients" component={ClientTable} />
          </Switch>
          <Switch>
            <PrivateRoute exact path="/clientsDist" component={ClientTableDist} />
          </Switch>
          <Switch>
            <PrivateRoute exact path="/Warning" component={TableWarning} />
          </Switch>
          <Switch>
            <PrivateRoute exact path="/galebstart" component={ClientInfo} />
          </Switch>
          <Switch>
            <PrivateRoute exact path="/galebstart/createG" component={CreategalebStart} />
          </Switch>
          <Switch>
            <PrivateRoute exact path="/galebstart/updateClient/:_id" component={EditGalebStart} />
          </Switch>
          <Switch>
            <PrivateRoute exact path='/galebstart/clientCallHistory/:_id' component={GSClientCallHistory} />
          </Switch>
          <Switch>
            <PrivateRoute exact path='/alati' component={tools} />
          </Switch>
          <Switch>
            <PrivateRoute exact path="/clientsPM" component={ClientTablePM} />
          </Switch>
          <Switch>
            <PrivateRoute exact path="/clients/:_id" component={Client} />
          </Switch>
          <Switch>
            <PrivateRoute exact path="/profiles" component={Profiles} />
          </Switch>
          <Switch>
            <PrivateRoute exact path="/profiles/:id" component={ProfileEdit} />
          </Switch>
          <Switch>
            <PrivateRoute exact path="/register" component={Register} />
          </Switch>
          <Switch>
            <PrivateRoute exact path="/confirmation" component={Confirmation} />
          </Switch>
          <Switch>
            <PrivateRoute exact path="/iconfirmation" component={InvoicesConfrimation} />
          </Switch>
          <Switch>
            <PrivateRoute exact path="/Invoice" component={Invoice} />
          </Switch>
          <Switch>
            <PrivateRoute exact path="/Licence" component={Licence} />
          </Switch>
          <Switch>
            <PrivateRoute exact path="/statistics" component={Statistics} />
          </Switch>
          <Switch>
            <PrivateRoute exact path="/clients/history-table/:cbLicenceId" component={HistoryTable} />
          </Switch>
          <Switch>
            <PrivateRoute exact path="/deletedClients" component={DeletedClientsTable} />
          </Switch>
          <Switch>
            <PrivateRoute exact path="/deletedClientsDist" component={DeletedClientsDistTable} />
          </Switch>
          <Switch>
            <PrivateRoute exact path="/EInvoicesDist" component={ClientsEInvoiceTableDist} />
          </Switch>
          <Switch>
            <PrivateRoute exact path="/galebshop" component={AdminEShop} />
          </Switch>
          <Switch>
            <PrivateRoute exact path="/galebshop/create-product" component={CreateProduct} />
          </Switch>
          <Switch>
            <PrivateRoute exact path="/galebshop/products" component={AllProducts} />
          </Switch>
          <Switch>
            <PrivateRoute exact path="/galebshop/products/update-product/:slug" component={UpdateProduct} />
          </Switch>
          <Switch>
            <PrivateRoute exact path="/galebshop/orders" component={Orders} />
          </Switch>
          <Switch>
            <PrivateRoute exact path="/galebshop/orders/orders-card" component={CardOrders} />
          </Switch>
          <Switch>
            <PrivateRoute exact path="/galebshop/orders/orders-cash" component={CashOrders} />
          </Switch>
          <Switch>
            <PrivateRoute exact path="/galebshop/orders/orders-ips" component={IPSOrders} />
          </Switch>
          <Switch>
            <PrivateRoute exact path="/galebshop/orders/orders-predracun" component={PredracunOrders} />
          </Switch>
          <Switch>
            <PrivateRoute exact path="/galebshop/orders/ordersVendor" component={AllOrdersVendor} />
          </Switch>
          <Footer />
        </div>
      </div>
    );
  }
}
const mapStateToProps = state => ({
  auth: state.auth
});
export default connect(mapStateToProps)(Dashboard);
