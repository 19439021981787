const komercDistRoutes = [
  { path: '/clientsDist', name: 'Klijenti', icon: 'pe-7s-users' },
  { path: '/EInvoicesDist', name: 'E-fakture klijenti', icon: 'pe-7s-notebook' },
  { path: '/licence', name: 'Registruj licencu', icon: 'pe-7s-ticket' },
  { path: '/PersonalLogs', name: 'Moji logovi', icon: 'pe-7s-notebook'},
  { path: '/BlackListDist', name: 'Lista blokiranih uredajaj', icon: 'pe-7s-attention'},
  { path: '/deletedClientsDist', name: 'Izbrisani klijenti', icon: 'pe-7s-junk' },
  { path: '/invoice', name: 'Registruj e-fakture licencu', icon: 'pe-7s-ticket'},
  { path: '/DistCheckTable', name: 'Distributeri', icon: 'pe-7s-display1' },
  { path: '/statistics', name: 'Statistika', icon: 'pe-7s-graph3' },
  //{ path: '/warning', name: 'Za iskljucenje', icon: 'pe-7s-stopwatch' },
  { path: '/edit-profile', name: 'Korisnički profil', icon: 'pe-7s-user' },
  { redirect: true, path: '/confirmation', to: '/confirmation' },
  { redirect: true, path: '/iconfirmation', to: '/iconfirmation'},
  { redirect: true, path: '/', to: '/login', name: 'Login' }
];

export default komercDistRoutes;