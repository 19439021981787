const distRoutes = [
  { path: '/clientsDist', name: 'Klijenti', icon: 'pe-7s-users' },
  { path: '/EInvoicesDist', name: 'E-fakture klijenti', icon: 'pe-7s-notebook' },
  { path: '/licence', name: 'Registruj licencu', icon: 'pe-7s-ticket' },
  { path: '/InvoiceRegistration', name: 'Registracija E-fakture licence', icon: 'pe-7s-ticket' },
  { path: '/BlackListDist', name: 'Lista blokiranih uredajaj', icon: 'pe-7s-attention'},
  { path: '/deletedClientsDist', name: 'Izbrisani klijenti', icon: 'pe-7s-junk' },
  { path: '/edit-profile', name: 'Korisnički profil', icon: 'pe-7s-user' },
   { path: '/galebshop/orders/ordersVendor', name: 'Eshop', icon: 'pe-7s-cart' },
  { redirect: true, path: '/confirmation', to: '/confirmation' },
  { redirect: true, path: '/iconfirmation', to: '/iconfirmation'},
  { redirect: true, path: '/', to: '/login', name: 'Login' }
];

export default distRoutes;